export default {
  'form.submitButton': {
    id: 'contactForm.form.submitButton',
    defaultMessage: 'Send message'
  },

  'form.checkbox': {
    id: 'form.checkbox',
    defaultMessage:
      'I consent to storage of my data according to Privacy Policys'
  }
}
