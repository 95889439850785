import React, { Component, Fragment } from 'react'

class FormattedMessage extends Component {
  render() {
    const { defaultMessage } = this.props

    return <Fragment>{defaultMessage}</Fragment>
  }
}

export default FormattedMessage
