import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classNames from 'classnames'
import './styles.css'

class Button extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    disabled: PropTypes.bool,
    to: PropTypes.string,
    href: PropTypes.string,
    color: PropTypes.oneOf(['red', 'green', 'blue', 'gray']),
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    icon: PropTypes.oneOf(['left', 'right']),
    transparent: PropTypes.oneOf(['red', 'green', 'blue', 'currentColor']),
    wide: PropTypes.bool,
    fullWidth: PropTypes.bool,
    target: PropTypes.string,
    style: PropTypes.object,
    uppercase: PropTypes.bool,
    minWidth: PropTypes.string
  }

  static defaultProps = {
    type: 'button',
    target: '_self'
  }

  render() {
    const {
      className,
      children,
      onClick,
      type,
      disabled,
      to,
      href,
      color,
      size,
      icon,
      transparent,
      wide,
      fullWidth,
      target,
      style,
      uppercase,
      minWidth
    } = this.props

    const iconClass = icon || false
    const transparentClass = transparent || false
    const whiteText = color === 'red' || color === 'green' || color === 'blue'
    const iconWrapperClasses = 'flex items-center justify-center'
    const minWidthStyle = { minWidth: minWidth }

    const butnClasses = classNames(
      {
        'butn sans-serif mb0 pointer tc fw4 lh-solid': true,
        'outline-none v-mid no-underline dib nowrap': true,
        white: whiteText,
        'dark-gray bg-light-gray': !whiteText && !transparentClass,
        f13: size === 'xs',
        f12: size === 'sm',
        f9: size === 'lg',
        'bg-red': color === 'red',
        'bg-green': color === 'green',
        'bg-blue': color === 'blue',
        'b--currentColor current-color': transparent === 'currentColor',
        'b--currentColor red': transparent === 'red',
        'b--currentColor green': transparent === 'green',
        'b--currentColor blue': transparent === 'blue',
        'butn--icon-left': icon === 'left',
        'butn--icon-right': icon === 'right',
        'butn--wide': wide,
        'w-100': fullWidth,
        ttu: uppercase
      },
      className
    )

    if (to) {
      return (
        <Link
          to={to}
          role="button"
          className={butnClasses}
          disabled={disabled}
          style={style || minWidthStyle}
        >
          {iconClass ? (
            <span className={iconWrapperClasses}>{children}</span>
          ) : (
            children
          )}
        </Link>
      )
    }

    if (href) {
      return (
        <a
          href={href}
          role="button"
          className={butnClasses}
          onClick={onClick}
          disabled={disabled}
          target={target}
          style={style || minWidthStyle}
        >
          {iconClass ? (
            <span className={iconWrapperClasses}>{children}</span>
          ) : (
            children
          )}
        </a>
      )
    }

    return (
      <button
        type={type}
        className={butnClasses}
        onClick={onClick}
        disabled={disabled}
        style={style || minWidthStyle}
      >
        {iconClass ? (
          <span className={iconWrapperClasses}>{children}</span>
        ) : (
          children
        )}
      </button>
    )
  }
}

export default Button
