import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classNames from 'classnames'

const TermsPrivacyWrapper = ({ children }) => (
  <div className="flex-l items-start pv4 pv5-l">
    <div
      className={classNames({
        'bg-white pv2 pv3-l w-25-l': true,
        'ba br-0-l bb-0 bb-l b--moon-gray': true
      })}
    >
      <NavLink to="/terms" text="Terms" />
      <NavLink to="/privacy" text="Privacy" />
      <NavLink to="/rekisteriseloste" text="Rekisteriseloste (in Finnish)" />
    </div>

    <div className="ba b--moon-gray bg-white pa3 pa4-l w-75-l">{children}</div>
  </div>
)

const NavLink = ({ to, text }) => (
  <Link
    to={to}
    activeClassName="green bl b--green bw2"
    className="db fw5 f11 black hover-green no-underline pv2 pv3-l ph3 ph4-l"
  >
    {text}
  </Link>
)

TermsPrivacyWrapper.propTypes = {
  children: PropTypes.node
}

export default TermsPrivacyWrapper
