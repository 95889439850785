import React from 'react'

import FormattedMessage from '@/formatted-message'
import Button from '@/button'
import Icon from '@/icon'

import intlMessages from './messages'

const ContactForm = () => (
  <form
    action="https://formspree.io/f/mayarbqo"
    method="POST"
    className="mw5 mb4"
  >
    <input className="db mb3" type="email" name="_replyto" placeholder="Email" required />
    <input className="db mb3" type="text" name="_subject" placeholder="Subject" required />
    <textarea className="db mb3" name="_message" placeholder="Message" required rows="4"/>
    <label className="db mb3 f12 lh-title">
      <input type="checkbox" required className="mr2" />
      <FormattedMessage {...intlMessages['form.checkbox']} />
    </label>
    <Button
      type="submit"
      color="blue"
      icon="right"
      fullWidth
    >
      <FormattedMessage {...intlMessages['form.submitButton']} />
      <Icon icon="paper-plane" size="18px" />
    </Button>
  </form>
)

export default ContactForm
