import React, { Component } from 'react'

class FormattedHTMLMessage extends Component {
  render() {
    const { defaultMessage } = this.props

    return <span dangerouslySetInnerHTML={{ __html: defaultMessage }} />
  }
}

export default FormattedHTMLMessage
