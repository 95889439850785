import React, { Component } from 'react'
import DocumentMeta from 'react-document-meta'

import FormattedHTMLMessage from '@/formatted-html-message'
import Layout from '@/layout'
import Container from '@/container'
import ContactForm from '@/contact-form'
import TermsPrivacyWrapper from '@/terms-privacy-wrapper'

import intlMessages from 'i18n/termsMessages'

class TermsPage extends Component {
  render() {
    return (
      <Layout>
        <DocumentMeta
          title={intlMessages.documentTitle.defaultMessage}
          extend
        />
        <Container>
          <TermsPrivacyWrapper>
            <FormattedHTMLMessage {...intlMessages.termsOfUse} />
            <ContactForm />
            <FormattedHTMLMessage {...intlMessages['form.gdpr']} />
          </TermsPrivacyWrapper>
        </Container>
      </Layout>
    )
  }
}

export default TermsPage
